import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 2560.000000 2560.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">


<path d="M8190 19125 c-129 -27 -234 -77 -344 -166 -133 -106 -227 -245 -278
-409 l-23 -75 0 -925 c0 -871 1 -928 18 -985 46 -146 115 -263 218 -365 80
-80 158 -133 262 -178 143 -62 151 -62 1087 -60 l845 3 0 95 0 95 -860 6
c-883 5 -885 5 -987 46 -117 46 -237 154 -305 274 -81 143 -77 89 -81 1038 -3
947 -4 928 67 1077 68 142 215 266 376 317 l70 22 4550 0 4550 0 67 -22 c228
-75 387 -244 433 -461 13 -63 15 -195 15 -900 0 -924 1 -905 -69 -1047 -61
-124 -166 -226 -294 -286 -125 -58 -140 -59 -1033 -59 l-814 0 0 -100 0 -100
838 0 c513 0 861 4 899 10 259 42 518 254 617 504 56 141 56 128 56 1074 0
960 1 948 -62 1094 -39 93 -92 171 -171 251 -80 82 -145 128 -249 177 -164 76
275 70 -4792 69 -3733 -1 -4553 -3 -4606 -14z"/>
<path d="M6420 18443 c-9 -7 -70 -14 -141 -17 -180 -8 -271 -15 -294 -26 -23
-10 -64 -19 -118 -27 -20 -3 -40 -9 -43 -14 -3 -5 -20 -9 -39 -9 -19 0 -37 -4
-40 -10 -3 -5 -15 -10 -25 -10 -11 0 -35 -9 -55 -20 -20 -11 -42 -20 -50 -20
-8 0 -30 -9 -50 -20 -20 -11 -44 -20 -55 -20 -10 0 -22 -4 -25 -10 -3 -5 -14
-10 -25 -10 -10 0 -20 -6 -23 -14 -3 -8 -22 -22 -41 -31 -20 -10 -36 -21 -36
-26 0 -5 -6 -9 -14 -9 -8 0 -31 -16 -50 -35 -20 -19 -41 -35 -46 -35 -14 0
-220 -205 -220 -219 0 -6 -15 -29 -34 -49 -40 -43 -86 -109 -86 -122 0 -6 -4
-10 -10 -10 -5 0 -10 -6 -10 -12 0 -7 -18 -49 -40 -93 -22 -44 -40 -84 -40
-88 0 -5 -10 -30 -23 -56 -13 -25 -29 -66 -36 -91 -7 -25 -17 -59 -22 -76 -5
-17 -9 -47 -9 -67 0 -20 -4 -39 -10 -42 -5 -3 -10 -34 -10 -69 0 -34 -4 -66
-9 -71 -14 -14 -21 -210 -27 -717 -3 -283 -8 -448 -14 -448 -7 0 -10 -387 -10
-1095 0 -723 3 -1095 10 -1095 7 0 10 -220 10 -639 0 -416 4 -642 10 -646 6
-4 10 -78 10 -191 0 -106 4 -184 9 -184 5 0 12 -39 15 -87 9 -149 16 -217 26
-238 5 -11 11 -45 14 -75 12 -129 22 -188 35 -204 8 -10 11 -27 8 -45 -4 -17
0 -36 8 -47 8 -11 15 -42 15 -71 0 -28 4 -54 9 -57 8 -6 12 -24 27 -128 3 -24
10 -47 15 -52 5 -6 9 -27 9 -47 0 -21 5 -41 10 -44 6 -3 10 -21 10 -40 0 -19
5 -37 10 -40 6 -3 10 -21 10 -40 0 -19 5 -37 10 -40 6 -3 10 -21 10 -40 0 -19
5 -37 10 -40 6 -3 10 -17 10 -30 0 -13 5 -27 10 -30 6 -3 10 -21 10 -40 0 -19
4 -37 10 -40 6 -3 10 -17 10 -30 0 -13 4 -27 10 -30 6 -3 10 -21 10 -40 0 -19
5 -37 10 -40 6 -3 10 -19 10 -36 0 -16 5 -29 10 -29 6 0 10 -13 10 -29 0 -17
5 -33 10 -36 6 -3 10 -17 10 -30 0 -13 4 -27 10 -30 6 -3 10 -17 10 -30 0 -13
4 -27 10 -30 6 -3 10 -17 10 -30 0 -13 4 -27 10 -30 6 -3 10 -15 10 -25 0 -11
9 -35 20 -55 11 -20 20 -42 20 -50 0 -8 9 -29 20 -48 12 -19 23 -43 25 -53 7
-36 28 -89 56 -148 16 -32 29 -66 29 -75 0 -9 9 -31 20 -49 11 -18 20 -38 20
-45 0 -8 27 -67 60 -132 33 -65 60 -124 60 -131 0 -6 14 -38 30 -69 17 -31 30
-63 30 -70 0 -7 3 -15 8 -17 10 -4 52 -87 52 -103 0 -7 7 -18 16 -26 9 -7 40
-61 70 -120 29 -59 58 -110 64 -114 5 -3 19 -26 30 -50 11 -24 24 -47 30 -50
6 -3 19 -26 30 -50 11 -24 24 -47 30 -50 6 -3 19 -25 30 -49 10 -23 26 -49 35
-56 8 -7 15 -18 15 -25 0 -6 9 -20 20 -30 11 -10 20 -23 20 -30 0 -7 9 -20 20
-30 11 -10 20 -23 20 -30 0 -7 9 -20 20 -30 11 -10 20 -24 20 -32 0 -7 9 -21
20 -30 11 -10 20 -23 20 -29 0 -6 11 -23 25 -38 14 -15 25 -31 25 -37 0 -5 13
-23 30 -41 16 -17 30 -36 30 -43 0 -7 9 -20 20 -30 11 -10 20 -22 20 -27 0 -4
14 -22 30 -40 17 -17 30 -35 30 -40 0 -4 14 -22 30 -40 16 -17 30 -36 30 -42
0 -7 18 -30 40 -51 22 -21 40 -43 40 -49 0 -10 45 -63 148 -175 34 -37 62 -72
62 -77 0 -5 36 -45 80 -89 44 -44 80 -84 80 -90 0 -18 656 -655 675 -655 4 0
34 -24 66 -52 116 -104 156 -138 165 -138 6 0 28 -18 49 -40 21 -22 44 -40 50
-40 6 0 24 -13 40 -30 16 -16 34 -30 40 -30 6 0 24 -13 40 -30 16 -16 34 -30
40 -30 6 0 24 -13 40 -30 16 -17 34 -30 40 -30 7 0 20 -9 30 -20 10 -11 24
-20 30 -20 7 0 19 -7 26 -16 7 -9 22 -20 34 -25 11 -5 40 -25 65 -44 25 -19
54 -39 65 -44 12 -5 27 -16 34 -25 7 -9 20 -16 27 -16 8 0 22 -9 31 -20 10
-11 22 -20 29 -20 6 0 19 -9 29 -20 10 -11 26 -20 36 -20 9 0 19 -3 21 -7 4
-10 57 -42 81 -50 9 -3 17 -9 17 -14 0 -5 7 -9 15 -9 8 0 23 -9 32 -20 10 -11
23 -20 31 -20 12 0 62 -28 72 -41 3 -4 63 -36 133 -72 71 -36 135 -71 143 -78
20 -17 226 -119 240 -119 6 0 55 -23 109 -50 54 -28 104 -50 111 -50 10 0 43
-15 116 -51 10 -5 27 -9 37 -9 11 0 22 -4 26 -9 9 -15 67 -41 77 -35 5 3 17 0
26 -8 27 -21 61 -38 77 -38 8 0 30 -9 50 -20 20 -11 44 -20 55 -20 10 0 22 -5
25 -10 3 -6 17 -10 30 -10 13 0 27 -4 30 -10 3 -5 17 -10 30 -10 13 0 27 -5
30 -10 3 -6 15 -10 25 -10 11 0 35 -9 55 -20 20 -11 51 -20 70 -20 19 0 35 -4
35 -10 0 -5 13 -10 28 -10 15 0 32 -4 38 -8 5 -5 29 -14 54 -21 25 -7 53 -17
62 -22 10 -5 27 -9 37 -9 11 0 23 -4 26 -10 3 -5 21 -10 40 -10 19 0 37 -4 40
-10 3 -5 17 -10 30 -10 13 0 27 -4 30 -10 3 -5 21 -10 40 -10 19 0 37 -4 40
-10 3 -5 21 -10 40 -10 19 0 37 -4 40 -10 3 -6 17 -10 30 -10 13 0 27 -4 30
-10 3 -5 25 -10 49 -10 23 0 51 -6 61 -14 10 -7 27 -11 37 -8 10 2 29 -3 43
-12 13 -9 42 -16 64 -16 22 0 43 -5 46 -10 3 -6 26 -10 50 -10 24 0 47 -4 50
-10 3 -5 30 -10 60 -10 30 0 57 -4 60 -10 3 -5 26 -10 51 -10 26 0 52 -6 60
-14 12 -12 14 -158 14 -990 l0 -976 1265 0 1265 0 0 976 c0 832 2 978 14 990
9 9 35 14 68 14 30 0 58 4 64 9 8 8 37 14 144 27 25 3 49 10 54 15 6 5 28 9
50 9 23 0 49 7 59 15 11 7 28 12 39 10 11 -3 30 2 42 10 11 8 39 15 63 15 23
0 45 5 49 11 3 6 17 9 30 6 13 -2 32 2 41 9 10 7 32 14 50 14 17 0 35 5 38 10
3 6 21 10 40 10 19 0 37 5 40 10 3 6 17 10 30 10 13 0 27 5 30 10 3 5 19 10
36 10 16 0 37 4 47 9 27 14 148 51 166 51 9 0 16 5 16 10 0 6 14 10 30 10 17
0 30 5 30 10 0 6 13 10 29 10 17 0 33 4 36 10 3 5 17 10 30 10 13 0 27 5 30
10 3 6 17 10 30 10 13 0 27 4 30 10 3 6 15 10 25 10 11 0 35 9 55 20 20 11 42
20 50 20 8 0 30 9 50 20 20 11 42 20 49 20 8 0 31 8 53 18 21 10 65 31 98 46
33 15 68 32 77 37 10 5 24 9 31 9 17 0 141 62 145 73 2 4 14 7 26 7 22 0 506
238 535 264 9 7 52 33 96 56 128 67 190 102 195 110 3 4 23 15 45 26 22 10 47
26 56 36 9 10 22 18 28 18 14 0 49 20 75 43 11 9 25 17 32 17 6 0 18 7 25 16
7 9 22 20 34 25 11 5 40 25 65 44 25 19 54 39 65 44 12 5 27 16 34 25 7 9 19
16 26 16 6 0 20 9 30 20 10 11 23 20 30 20 6 0 24 14 40 30 16 17 34 30 40 30
6 0 24 14 40 30 16 17 34 30 40 30 7 0 20 9 30 20 10 11 23 20 30 20 6 0 29
18 50 40 21 22 44 40 50 40 6 0 33 22 60 50 27 27 54 50 60 50 6 0 48 38 95
85 47 47 89 85 95 85 13 0 735 722 735 734 0 6 37 49 83 98 131 139 187 204
187 214 0 6 18 28 40 49 22 21 40 45 40 53 0 7 14 25 30 39 17 13 30 30 30 37
0 7 14 25 30 41 17 16 30 36 30 45 0 8 9 22 20 30 11 8 20 19 20 25 0 6 16 28
35 49 19 21 35 44 35 51 0 8 4 15 8 17 10 4 72 96 72 107 0 4 9 16 20 26 11
10 20 23 20 30 0 7 9 20 20 30 11 10 20 24 20 32 0 7 5 13 10 13 6 0 10 8 10
18 0 11 9 27 20 37 11 10 20 24 20 30 0 7 7 18 15 25 9 7 25 33 35 56 11 24
24 45 30 49 5 3 23 35 40 70 17 35 35 67 40 70 12 7 40 65 40 82 0 7 4 13 8
13 5 0 41 68 81 150 41 83 77 153 82 155 10 7 39 65 39 80 0 6 27 65 60 130
33 65 60 124 60 131 0 8 9 29 20 49 11 20 20 41 20 48 0 7 14 39 30 70 17 32
30 64 30 71 0 7 11 34 24 59 14 25 22 48 19 51 -3 3 2 17 11 30 9 14 16 32 16
40 0 9 9 31 20 51 11 20 20 44 20 55 0 10 5 22 10 25 6 3 10 17 10 30 0 13 5
27 10 30 5 3 10 17 10 30 0 13 5 27 10 30 6 3 10 17 10 30 0 13 5 27 10 30 6
3 10 17 10 30 0 13 5 27 10 30 6 3 10 19 10 36 0 16 5 29 10 29 6 0 10 14 10
30 0 17 5 30 10 30 6 0 10 13 10 29 0 17 5 33 10 36 5 3 10 21 10 40 0 19 5
37 10 40 6 3 10 17 10 30 0 13 5 27 10 30 6 3 10 21 10 40 0 19 5 37 10 40 6
3 10 21 10 40 0 19 5 37 10 40 6 3 10 21 10 40 0 19 4 36 9 39 5 3 11 23 14
43 11 72 18 108 27 128 5 11 11 36 14 55 2 19 10 55 16 80 7 25 14 69 17 99 3
30 12 65 20 78 9 14 13 33 9 48 -4 16 0 34 9 49 10 14 15 41 14 68 -1 23 3 53
10 66 6 12 11 40 9 62 -1 22 3 45 10 52 7 7 12 43 12 90 0 45 4 78 10 78 6 0
10 36 10 89 0 50 5 93 10 96 6 4 10 70 10 165 0 87 4 161 9 164 14 9 22 2008
10 2816 -13 982 -18 1173 -29 1184 -6 6 -10 45 -10 87 0 43 -4 81 -10 84 -5 3
-10 21 -10 40 0 19 -4 37 -10 40 -5 3 -10 18 -10 33 -1 15 -7 38 -14 51 -8 14
-12 30 -9 37 2 7 0 15 -6 19 -6 3 -11 17 -11 29 0 13 -7 29 -15 36 -8 7 -15
23 -15 36 0 24 -78 186 -92 192 -5 2 -8 12 -8 21 0 10 -9 26 -20 36 -11 10
-20 23 -20 30 0 7 -9 20 -20 30 -11 10 -20 23 -20 29 0 14 -292 306 -306 306
-6 0 -19 9 -29 20 -10 11 -23 20 -28 20 -6 0 -18 7 -27 15 -26 24 -188 105
-209 105 -10 0 -24 6 -30 13 -6 8 -30 19 -53 26 -24 7 -51 17 -60 22 -10 5
-27 9 -37 9 -11 0 -23 5 -26 10 -3 6 -21 10 -40 10 -19 0 -37 5 -40 10 -3 6
-26 10 -50 10 -24 0 -47 5 -50 10 -3 6 -30 10 -60 10 -30 0 -57 5 -60 10 -3 6
-39 10 -79 10 -39 0 -77 5 -82 10 -7 7 -168 9 -465 8 l-454 -3 0 -935 0 -935
315 -5 c173 -3 316 -7 318 -8 1 -1 2 -598 2 -1326 0 -873 -3 -1327 -10 -1331
-6 -4 -10 -155 -10 -420 0 -265 -4 -416 -10 -420 -6 -4 -10 -63 -10 -145 0
-82 -4 -141 -10 -145 -5 -3 -10 -44 -10 -90 0 -46 -4 -87 -10 -90 -5 -3 -10
-32 -10 -64 0 -39 -5 -63 -15 -71 -9 -8 -13 -25 -10 -52 2 -24 -2 -53 -11 -69
-7 -15 -14 -43 -14 -62 0 -18 -4 -37 -9 -42 -5 -6 -12 -31 -15 -57 -3 -27 -9
-69 -12 -95 -3 -27 -10 -52 -15 -58 -5 -5 -9 -24 -9 -41 0 -18 -4 -36 -10 -39
-5 -3 -10 -21 -10 -40 0 -19 -5 -37 -10 -40 -5 -3 -10 -21 -10 -40 0 -19 -4
-37 -10 -40 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -19 -10
-36 0 -16 -4 -37 -9 -47 -5 -9 -19 -51 -31 -92 -12 -41 -26 -83 -31 -92 -5
-10 -9 -27 -9 -37 0 -11 -5 -23 -10 -26 -5 -3 -10 -17 -10 -30 0 -13 -4 -27
-10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -15 -10 -25 0
-11 -9 -35 -20 -55 -11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20 -50 -11 -20 -20
-42 -20 -49 0 -8 -14 -40 -30 -71 -17 -31 -30 -61 -30 -67 0 -14 -260 -537
-270 -543 -11 -7 -60 -106 -60 -121 0 -6 -7 -14 -15 -17 -8 -3 -24 -28 -36
-54 -12 -26 -27 -51 -34 -55 -7 -4 -18 -21 -25 -38 -7 -16 -16 -30 -21 -30 -5
0 -9 -6 -9 -14 0 -8 -11 -28 -25 -44 -14 -17 -25 -34 -25 -39 0 -6 -9 -18 -20
-28 -11 -10 -20 -23 -20 -30 0 -7 -9 -20 -20 -30 -11 -10 -20 -23 -20 -30 0
-7 -9 -20 -20 -30 -11 -10 -20 -23 -20 -30 0 -6 -14 -24 -30 -40 -17 -16 -30
-34 -30 -40 0 -6 -13 -24 -30 -40 -16 -16 -30 -34 -30 -40 0 -6 -18 -29 -40
-50 -22 -21 -40 -44 -40 -50 0 -6 -25 -35 -55 -65 -30 -30 -55 -59 -55 -65 0
-6 -27 -37 -60 -70 -33 -33 -60 -64 -60 -70 0 -13 -462 -475 -475 -475 -6 0
-37 -27 -70 -60 -33 -33 -64 -60 -70 -60 -6 0 -33 -22 -60 -50 -27 -27 -54
-50 -60 -50 -6 0 -29 -18 -50 -40 -21 -22 -44 -40 -50 -40 -6 0 -26 -16 -45
-35 -19 -19 -39 -35 -45 -35 -7 0 -20 -9 -30 -20 -10 -11 -23 -20 -30 -20 -6
0 -24 -13 -40 -30 -16 -16 -34 -30 -40 -30 -7 0 -20 -9 -30 -20 -10 -11 -23
-20 -30 -20 -7 0 -20 -9 -30 -20 -10 -11 -23 -20 -30 -20 -7 0 -20 -9 -30 -20
-10 -11 -24 -20 -30 -20 -7 0 -18 -7 -25 -15 -7 -9 -33 -25 -56 -35 -24 -11
-46 -25 -50 -31 -3 -6 -25 -20 -48 -31 -22 -10 -59 -32 -81 -48 -22 -16 -48
-29 -57 -30 -10 0 -18 -4 -18 -10 0 -5 -21 -20 -47 -32 -27 -12 -49 -26 -51
-30 -2 -4 -12 -8 -23 -8 -10 0 -19 -4 -19 -10 0 -5 -9 -10 -20 -10 -11 0 -20
-4 -20 -10 0 -5 -6 -10 -13 -10 -7 0 -76 -32 -152 -70 -76 -39 -144 -70 -150
-70 -7 0 -47 -18 -90 -40 -43 -22 -83 -40 -90 -40 -7 0 -34 -12 -59 -26 -26
-15 -49 -24 -52 -21 -3 3 -25 -5 -47 -17 -23 -12 -76 -33 -117 -45 -41 -13
-83 -27 -92 -32 -10 -5 -27 -9 -37 -9 -11 0 -23 -4 -26 -10 -3 -5 -17 -10 -30
-10 -13 0 -27 -4 -30 -10 -3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5
-17 -10 -30 -10 -13 0 -27 -5 -30 -10 -3 -6 -21 -10 -40 -10 -19 0 -37 -4 -40
-10 -3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -21 -10 -40 -10 -19 0
-37 -4 -40 -10 -3 -5 -24 -10 -46 -10 -21 0 -39 -4 -39 -10 0 -5 -18 -10 -39
-10 -22 0 -42 -4 -45 -9 -6 -9 -45 -18 -126 -27 -25 -3 -49 -10 -54 -15 -6 -5
-29 -9 -52 -9 -24 0 -46 -4 -49 -10 -3 -5 -30 -10 -59 -10 -28 0 -57 -5 -63
-11 -5 -5 -39 -12 -74 -15 -157 -11 -227 -20 -240 -30 -8 -7 -54 -12 -103 -13
-49 -1 -97 -6 -107 -12 -25 -13 -926 -12 -934 1 -3 6 -51 10 -109 10 -81 0
-106 3 -116 15 -10 12 -31 15 -86 14 -41 -1 -79 3 -86 8 -7 6 -35 10 -61 10
-27 0 -53 5 -60 12 -6 6 -35 11 -63 11 -29 0 -56 4 -59 10 -3 5 -25 10 -49 10
-23 0 -46 4 -51 9 -6 5 -30 11 -55 14 -25 3 -70 11 -100 17 -30 6 -72 14 -93
17 -20 3 -40 9 -43 14 -3 5 -20 9 -39 9 -19 0 -37 4 -40 10 -3 6 -17 10 -30
10 -13 0 -27 5 -30 10 -3 6 -21 10 -40 10 -19 0 -37 5 -40 10 -3 6 -17 10 -30
10 -13 0 -27 5 -30 10 -3 6 -21 10 -40 10 -19 0 -37 5 -40 10 -3 6 -17 10 -30
10 -13 0 -27 5 -30 10 -3 6 -17 10 -30 10 -13 0 -27 5 -30 10 -3 6 -17 10 -30
10 -13 0 -27 5 -30 10 -3 6 -14 10 -24 10 -9 0 -36 9 -59 20 -22 11 -47 20
-54 20 -8 0 -23 7 -34 15 -12 8 -24 12 -27 9 -3 -3 -25 5 -49 18 -23 12 -50
23 -58 24 -8 1 -41 16 -74 33 -32 17 -63 31 -70 31 -7 0 -75 32 -151 70 -76
39 -145 70 -152 70 -7 0 -13 3 -13 8 0 8 -49 32 -67 32 -7 0 -13 5 -13 10 0 6
-9 10 -19 10 -11 0 -21 4 -23 8 -3 8 -92 52 -106 52 -4 0 -14 9 -22 20 -8 11
-20 20 -27 20 -8 0 -31 11 -53 25 -22 14 -44 25 -49 25 -5 0 -14 7 -21 15 -7
9 -32 25 -55 35 -23 10 -48 26 -55 35 -7 8 -18 15 -25 15 -6 0 -20 9 -30 20
-10 11 -23 20 -30 20 -7 0 -20 9 -30 20 -10 11 -23 20 -30 20 -7 0 -20 9 -30
20 -10 11 -23 20 -30 20 -7 0 -20 9 -30 20 -10 11 -23 20 -30 20 -7 0 -20 9
-30 20 -10 11 -22 20 -28 20 -5 0 -27 15 -48 32 -22 18 -56 48 -78 66 -21 17
-43 32 -48 32 -5 0 -27 18 -48 40 -21 22 -44 40 -50 40 -6 0 -29 18 -50 40
-21 22 -44 40 -50 40 -6 0 -37 27 -70 60 -33 33 -64 60 -70 60 -13 0 -535 522
-535 535 0 6 -27 37 -60 70 -33 33 -60 64 -60 69 0 5 -20 32 -45 60 -24 28
-63 72 -85 97 -22 25 -40 50 -40 56 0 5 -13 22 -30 38 -16 16 -30 34 -30 40 0
6 -13 24 -30 40 -16 16 -30 34 -30 40 0 7 -9 20 -20 30 -11 10 -20 22 -20 26
0 10 -45 79 -65 100 -8 9 -15 19 -15 23 0 5 -11 21 -25 38 -14 16 -25 36 -25
44 0 8 -5 14 -11 14 -5 0 -21 25 -35 55 -14 30 -30 57 -35 60 -5 4 -18 26 -29
50 -11 24 -24 46 -29 50 -5 3 -22 31 -37 63 -15 31 -34 64 -41 73 -21 25 -203
389 -203 406 0 8 -20 53 -45 100 -25 47 -45 91 -45 99 0 7 -9 29 -20 49 -11
20 -20 42 -20 50 0 8 -9 30 -20 50 -11 20 -20 44 -20 55 0 10 -4 22 -10 25 -5
3 -10 17 -10 30 0 13 -4 27 -10 30 -5 3 -10 17 -10 30 0 13 -4 27 -10 30 -5 3
-10 15 -10 26 0 10 -4 27 -9 37 -5 9 -19 51 -31 92 -12 41 -26 83 -31 92 -5
10 -9 26 -9 36 0 10 -4 22 -8 28 -5 5 -14 29 -21 54 -7 25 -17 53 -22 62 -5
10 -9 31 -9 47 0 17 -4 33 -10 36 -5 3 -10 17 -10 30 0 13 -4 27 -10 30 -5 3
-10 21 -10 40 0 19 -4 37 -10 40 -5 3 -10 21 -10 40 0 19 -4 37 -10 40 -5 3
-10 25 -10 48 0 24 -7 52 -15 63 -9 13 -14 43 -14 78 1 31 -4 62 -9 69 -12 14
-20 111 -28 307 -3 74 -9 139 -15 145 -5 5 -9 129 -9 296 0 181 -4 290 -10
294 -7 4 -10 401 -10 1159 0 760 -3 1155 -10 1162 -7 7 -9 257 -8 730 l3 719
315 5 315 5 3 943 c2 749 0 944 -10 947 -7 3 -174 6 -372 7 -265 2 -363 0
-376 -9z"/>
<path d="M8352 18144 c-117 -58 -146 -132 -141 -368 4 -155 5 -163 32 -208 15
-26 42 -59 60 -72 77 -59 6 -56 1232 -56 l1125 0 0 -125 0 -125 -1215 0 c-668
0 -1215 -3 -1215 -8 0 -4 51 -58 113 -120 l113 -112 1139 2 1140 3 40 25 c53
32 91 76 111 126 23 61 17 394 -9 442 -23 43 -69 86 -115 109 -35 17 -96 18
-1172 23 l-1135 5 0 120 0 120 1101 3 1102 2 116 116 c64 64 116 118 116 120
0 2 -559 4 -1243 4 l-1243 0 -52 -26z"/>
<path d="M11535 18163 c-50 -13 -118 -71 -148 -128 -22 -39 -22 -49 -25 -560
l-3 -520 121 120 120 120 0 367 0 368 490 0 490 0 0 -289 c0 -159 4 -300 9
-313 8 -21 104 -128 115 -128 2 0 31 27 65 60 l61 61 0 305 0 304 488 -2 487
-3 3 -365 2 -366 120 -119 120 -120 0 510 0 510 -24 51 c-27 58 -94 120 -145
134 -37 10 -2306 13 -2346 3z"/>
<path d="M14510 17560 l0 -610 1258 2 1257 3 42 22 c52 28 98 83 118 142 22
66 22 816 0 882 -22 63 -70 118 -129 145 l-51 24 -1247 0 -1248 0 0 -610z
m2445 0 l0 -365 -1102 -3 -1103 -2 0 370 0 370 1103 -2 1102 -3 0 -365z"/>
<path d="M12355 15310 c-3 -5 -37 -10 -76 -10 -85 0 -149 -31 -149 -71 0 -10
-9 -34 -20 -54 -11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20 -50 -11 -20 -20 -42
-20 -49 0 -8 -8 -31 -19 -53 -10 -21 -23 -53 -30 -70 -6 -18 -16 -48 -22 -65
-7 -18 -20 -50 -30 -71 -11 -22 -19 -45 -19 -53 0 -7 -11 -33 -24 -56 -13 -24
-26 -56 -30 -73 -4 -16 -14 -37 -22 -46 -8 -9 -14 -21 -12 -26 3 -8 -8 -37
-61 -158 -10 -22 -23 -56 -30 -75 -40 -110 -54 -146 -62 -155 -5 -5 -9 -17 -9
-27 0 -9 -9 -33 -20 -53 -11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20 -50 -11 -20
-20 -42 -20 -50 0 -8 -9 -30 -20 -50 -11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20
-50 -11 -20 -20 -47 -20 -60 0 -14 -3 -25 -7 -25 -10 0 -33 -49 -33 -67 0 -8
-11 -35 -25 -60 -13 -25 -22 -50 -19 -55 3 -5 -1 -17 -9 -26 -8 -9 -21 -35
-28 -57 -8 -22 -22 -55 -31 -73 -10 -18 -18 -39 -18 -47 0 -8 -9 -30 -20 -50
-11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20 -50 -11 -20 -20 -47 -20 -60 0 -14
-4 -25 -8 -25 -9 0 -32 -59 -32 -83 0 -9 -4 -17 -10 -17 -5 0 -10 -8 -10 -18
0 -10 -4 -22 -8 -28 -5 -5 -15 -28 -22 -51 -7 -24 -17 -43 -22 -43 -4 0 -8
-11 -8 -25 0 -13 -9 -40 -20 -60 -11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20 -50
-11 -20 -20 -42 -20 -50 0 -8 -9 -30 -20 -50 -11 -20 -20 -42 -20 -50 0 -8 -9
-29 -20 -47 -11 -18 -20 -41 -20 -51 0 -10 -12 -39 -26 -63 -15 -25 -24 -48
-20 -51 3 -4 -1 -17 -10 -31 -13 -20 -14 -26 -3 -33 8 -5 99 -9 203 -8 122 0
192 -3 199 -10 8 -8 140 -11 414 -11 392 0 403 -1 403 -19 0 -11 -4 -23 -10
-26 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13
-4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10
-30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3
-10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10
-30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13
-4 -27 -10 -30 -5 -3 -10 -19 -10 -34 0 -15 -3 -31 -7 -35 -10 -9 -33 -78 -33
-97 0 -9 -6 -22 -14 -28 -8 -7 -17 -34 -21 -61 -4 -27 -13 -54 -21 -61 -8 -6
-14 -18 -14 -27 0 -13 -50 -171 -69 -217 -5 -11 -14 -40 -21 -65 -6 -25 -16
-53 -21 -62 -5 -10 -9 -27 -9 -37 0 -11 -4 -23 -10 -26 -5 -3 -10 -17 -10 -30
0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10
-17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30
-5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -19 -10 -36 0 -16 -4
-29 -10 -29 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -9 -10 -20 0
-11 -4 -28 -9 -38 -5 -9 -14 -37 -21 -62 -7 -25 -16 -53 -21 -62 -5 -10 -9
-26 -9 -36 0 -10 -4 -22 -8 -27 -5 -6 -14 -30 -21 -55 -7 -25 -19 -57 -27 -71
-7 -14 -11 -29 -8 -32 3 -3 -1 -16 -9 -29 -15 -23 -28 -66 -32 -102 -1 -11 -7
-22 -13 -24 -7 -2 -12 -11 -12 -20 0 -18 -18 -78 -31 -104 -5 -10 -9 -27 -9
-37 0 -11 -4 -23 -10 -26 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3
-10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10
-30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13
-4 -27 -10 -30 -5 -3 -10 -15 -10 -26 0 -10 -4 -27 -9 -37 -13 -26 -31 -86
-31 -105 0 -9 -4 -17 -10 -17 -5 0 -10 -13 -10 -29 0 -17 -4 -33 -10 -36 -5
-3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27
-10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0
-13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17
-10 -30 0 -13 -4 -27 -10 -30 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5
-3 -10 -16 -10 -28 0 -13 -6 -31 -14 -42 -13 -21 -28 -66 -32 -98 -1 -10 -6
-23 -13 -30 -12 -12 -15 -67 -3 -67 4 0 934 927 2067 2060 1133 1134 2111
2112 2174 2174 81 81 112 118 108 130 -6 15 -64 16 -637 16 -413 0 -630 3
-630 10 0 6 -44 10 -113 10 -82 0 -122 4 -144 15 -29 15 -30 17 -21 48 6 17
17 44 25 59 7 15 12 30 11 35 -1 4 8 27 20 50 12 24 21 46 20 50 -1 5 8 28 20
52 12 25 22 53 22 62 0 10 5 21 10 24 6 3 10 15 10 25 0 11 9 35 20 55 11 20
20 42 20 50 0 8 9 30 20 50 11 20 20 44 20 55 0 10 5 22 10 25 6 3 10 15 10
26 0 10 5 19 10 19 6 0 10 9 10 20 0 11 9 35 20 55 11 20 20 47 20 60 0 14 3
25 8 25 9 0 32 49 32 67 0 7 8 32 19 56 10 23 24 58 31 77 8 19 19 42 26 50 7
8 13 18 14 22 3 23 44 125 81 201 11 22 19 45 19 53 0 7 9 29 20 49 11 20 20
41 20 48 0 7 9 32 20 55 11 22 20 48 20 56 0 9 9 31 20 51 11 20 20 42 20 50
0 8 9 30 20 50 11 20 20 42 20 50 0 8 9 30 20 50 11 20 20 42 20 50 0 8 9 30
20 50 11 20 20 44 20 55 0 10 5 22 10 25 6 3 10 15 10 25 0 11 9 35 20 55 11
20 20 41 20 48 0 7 9 32 20 55 11 22 20 46 20 52 0 6 11 33 25 61 14 27 23 54
20 59 -3 5 -1 11 5 15 5 3 17 21 26 40 33 70 129 65 -1155 65 -762 0 -1162 -3
-1166 -10z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
